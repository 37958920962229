import React,{useEffect, useState,useRef} from 'react';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Header from '../Header/Header';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

 function ViewConferenceBooking() 
 {
  //http://localhost:3002/api/admin/visitor
   
   //const VISITOR_URL = "http://localhost/eventapi/index.php/auth/getVisitorData";
   const View_Conference_Booking_URL = `${process.env.REACT_APP_API_URL}auth/getConferenceBookingData`;
   const delete_conference_booking_URL = `${process.env.REACT_APP_API_URL}auth/deleteData`;
   const invoice_URL = `${process.env.REACT_APP_PAYMENT_API_URL}api/uploads/invoice/`;

  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [visitorn,setvisitorn]= useState([]);


  async function deleteStudent(id) {
    const pdata={
      id:id,
      vtype:'cb'
    }
    const response = await fetch(delete_conference_booking_URL, {
      method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(pdata),
    });
    return response.json();
  }

  const deleteData = async id => {
    await deleteStudent(id);
    alert('Data delete successfully');
  };
  console.log(deleteData);

  // Download invoice



  useEffect(()=>{
    document.title="View Conference Booking Details - Auto EV Expo";
    axios.post(View_Conference_Booking_URL).then(res=>setvisitorn(res.data['data'])).catch(err=>console.log(err))},[visitorn]);

  return (
    <div>
      <Header/>
      <div className='container'>
      <div className='row'>
        <br/><br/><br/>
        <div className='col-md-8'><h3 className='mr-20'> View Conference Booking Details</h3></div><div className='col-md-4 align-right'><br/>
      <DownloadTableExcel
                    filename="users table"
                    sheet="users"
                    currentTableRef={tableRef.current}
                >

                   <button className='btn btn-success float-end'> Export excel </button>

                </DownloadTableExcel></div>
     
      <table class="table table-bordered table-sm" ref={tableRef}>
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Pass Type</th>
        <th scope="col">Booking No</th>
        <th scope="col">Name</th>
        <th scope="col">Designation</th>
        <th scope="col">Company<br/>Name</th>
        <th scope="col">City Name</th>
        <th scope="col">Phone</th>
        <th scope="col">Email</th>
        <th scope="col">Payment ID</th>
        <th scope="col">Status</th>
        <th scope="col">Submitted On</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      
     
      
      {visitorn &&  visitorn.map((data,i)=>(

      //let linkdow=invoice_URL+data.fld_filepath;
      <tr>
        <th scope="row">{i+1}</th>
        <td>{data.fld_pass_type}</td>
        <td>{data.fld_booking_id}</td>
        <td>{data.fld_name}</td>
        <td>{data.fld_desg}</td>
        <td>{data.fld_co}</td>
        <td>{data.fld_city}</td>
        <td>{data.fld_mob}</td>
        <td>{data.fld_email}</td>
        <td>{data.fld_transaction_id}</td>
        <td>{data.fld_payment_status}</td>
        <td>{data.fld_created_on}</td>
        <td> <a 
                      className="btn btn-danger"
                      onClick={() => deleteData(data.fld_booking_id)}
                    >
                      <DeleteIcon fontSize="small"/>
                    </a> &nbsp;
                    {data.fld_payment_status==='succeeded'?
                    <a 
                      className="btn btn-success" target='_blank'
                      href={invoice_URL+data.fld_filepath}>
                     <DownloadForOfflineIcon fontSize="small"/>
                    </a>:''}
                    </td>
      </tr>
      ))
 }
      
      
    </tbody>
  </table></div></div></div>
  )
}

export default ViewConferenceBooking;