import React from 'react'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Downloadfile from "../Assets/energyharvelimbattreplforiotnod.pdf";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

function Visiterthankyou() {
  const location=useLocation();
  console.log(location.state);
  return (
    <div>
      <Header/>
    <div className='container'>
    <div className='row'>
      <div className='shadow-sm p-10 mb-5 bg-light rounded mr-bottom '>
        <p>Dear User,</p>
        <p>Thank You for Registration.</p>
        
        <a href={Downloadfile} download className='btn btn-success'>Click to download</a> 
        <Link className="nav-link" to="/Whitepaper">Back</Link>
        </div>
     </div>
    {/* <Footer/> */}
    </div>
    </div>
  )
}

export default Visiterthankyou