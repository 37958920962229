import React, { useEffect } from 'react';
import Logo from "../Assets/Auto-EV-Logo-1.png";
import { Link } from 'react-router-dom';


function Header() {

     useEffect(()=>{
        const authvalue=localStorage.getItem('accessToken');
        console.log(authvalue);
     });
     
    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        //localStorage.removeItem("refreshToken");
        alert("Logged out");
        //navigate('/Login');
    
      };
     console.log(localStorage.getItem('accessToken'));
    return (
        <div>
            
                

               
            <nav className="navbar navbar-expand-lg navbar-dark bg-green">
                <div className="container-fluid container">
                <div className="col-md-3">
                    <a className="navbar-brand" href="/"><img src={Logo}
                        style={{ width: '200px' }} alt="logo" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    </div>
                    <div className='col-md-9'>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                           
                             { localStorage.getItem('accessToken') ? 
                             <>
                             
                            <li className="nav-item">
                                <Link className="nav-link" to="/ViewWhitepaper"> View White paper </Link>
                            </li>
                            
                            <li className="nav-item">
                                <a href="/" className="nav-link" onClick={handleLogout}>Logout</a>
                            </li>
                            </> : 
                            <>
                             {/* <li className="nav-item">
                                <Link className="nav-link" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/VisitorRegistration">Visitor Registration</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/ConferenceBooking">Conference Booking</Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to="/Login">Login</Link>
                            </li> */}
                            </>
                          }
                            
                        </ul>
                       
                    </div>
                </div></div>
            </nav> </div>
    )
}

export default Header;