import React,{ useState,useEffect} from 'react';
import {
   MDBContainer,
  MDBRow,
  MDBCol,
}
from 'mdb-react-ui-kit';
import Banner from "../Assets/banner_2.png";
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';


 function VisiterRegistration() {

  
  const createUserApi = `${process.env.REACT_APP_API_URL}auth/whitepaperDataSave`
  const navigate = useNavigate();
    const [error, setError] = useState([]);
    

    useEffect(()=>{
      document.title="Energy Harvesting: Eliminating Battery Replacements for IoT Nodes With 196 HVC ENYCAP™";
    },[]);
  

    // name, jobprofile, companyname,phone,email,city,referby,message
    const [user, setUser] = useState({
        firstname: "",
        lastname: "",
        companyname: "",
        phone: "",
        email: "",
        countryname: "",
        jobrole: "",
        industry:""
    });

    const handelInput = (event) => {
      event.preventDefault();
      const { name, value } = event.target;
      console.log(name, value)
      setUser({ ...user, [name]: value });
  }


  const validateForm = () => {
    let isValid = true;
    const newErrors = [];

    // Validate password
    if (!user.firstname) {
      newErrors.firstname = "First Name is required";
      isValid = false;
    }
    if (!user.lastname) {
      newErrors.lastname = "Last Name is required";
      isValid = false;
    }
    if (!user.jobrole) {
      newErrors.jobrole = "Select Job Role is required";
      isValid = false;
    }
   
  
    if (!user.email) {
      newErrors.email = "Email Address is required";
      isValid = false;
    }
   
    if (!user.industry) {
      newErrors.industry = "Select Industry is required";
      isValid = false;
    }
   

    setError(newErrors);
    return isValid;
  };


  const handelSubmit = async (event) => {
    event.preventDefault();
    if(!validateForm())
    {
      
      //console.log(error);
    }
    else{
    //console.log(user)
    try {
       
        const response = await fetch(createUserApi, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
        });

        if (response.ok) {
           // alert("Thank you for Registration");
            console.log('Form submitted successfully!');
            navigate('/Visiterthankyou',{state:user});
            // setUser({firstname: "",
            // lastname: "",
            // companyname: "",
            // phone: "",
            // email: "",
            // countryname: "",
            // jobrole: "",
            // industry: ""});
           
        } else {
            console.error('Form submission failed!');
        }

    } catch (error) {
        setError(error.message);
    } finally{
        
    }
  }
}



  return (
    <div>
       <Header />
     <div className='bg-green container-fluid text-center'>
      <img src={Banner} className='img-fluid' alt="Energy Harvesting: Eliminating Battery Replacements for IoT Nodes With 196 HVC ENYCAP™" />
     </div>
    <MDBContainer className="my-5 gradient-form">
      
      <MDBRow>
       
        <MDBCol col='8' className="mb-8">
        
          <div className="d-flex flex-column ms-5">


          <div className='user-form'>
            <div className='heading'>
              <h4> Energy Harvesting: Eliminating Battery Replacements for IoT Nodes With 196 HVC ENYCAP™</h4>
              <p>The V-harvester board is a photovoltaic (PV) harvesting backup demonstration circuit. It is a sophisticated stand-alone board charged using TEMD5080X01 micro PV cells or with micro USB. The input power goes into an e-peas low power AEM10941 controller, where it is stepped up to the supercapacitor voltage of 4.2 V. Upon power demands at the interface or ENLV, ENHV settings, the controller converts the supply voltage to the target voltages using low dropout regulators (LDO). </p>
          
            {/* {error && <p>Error: {error}</p>} */}
            <h2>Download Whitepaper</h2>
            </div>
            <form onSubmit={handelSubmit}>
            <div className='shadow-sm p-3 mb-5 bg-light rounded mr-bottom '>

            <div className="mb-3">
                    <label for="email" className="form-label required">Email Address</label>
                    <input type="email" className="form-control" id="email" name="email" value={user.email}  onChange={handelInput} />
                    {error.email && <div className="error">{error.email}</div>}
                </div>


                <MDBRow>
                <MDBCol col='6'>
                <div className="mb-3">
                    <label for="firstname" className="form-label required">First Name</label>
                    <input type="text" className="form-control" id="firstname" name="firstname" value={user.firstname}   onChange={handelInput} />
                    {error.firstname && <div className="error">{error.firstname}</div>}
                </div>
                </MDBCol>

                <MDBCol col='6'>
                <div className="mb-3">
                    <label for="lastname" className="form-label required">Last Name</label>
                    <input type="text" className="form-control" id="lastname" name="lastname" value={user.lastname}   onChange={handelInput} />
                    {error.lastname && <div className="error">{error.lastname}</div>}
                </div>
                </MDBCol>
              </MDBRow>

                

              
              <MDBRow>
                <MDBCol col='6'>
                <div className="mb-3">

                   <label for="countryname" className="form-label">Country/Region:</label>
                    <input type="text" className="form-control" id="countryname" name="countryname" value={user.countryname}   onChange={handelInput} />
                    {error.countryname && <div className="error">{error.countryname}</div>}
                    </div>
                    </MDBCol>
                   <MDBCol col='6'>
                   <div className="mb-3">
                    <label for="companyname" className="form-label">Company Name</label>
                    <input type="text" className="form-control" id="companyname" name="companyname" value={user.companyname}   onChange={handelInput} />
                    {error.companyname && <div className="error">{error.companyname}</div>}
                </div>
                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol col='6'>
                <div className="mb-3">
                    <label for="phone" className="form-label">Mobile phone number:</label>
                    <input type="text" className="form-control" id="phone" name="phone" value={user.phone}   onChange={handelInput} />
                    {error.phone && <div className="error">{error.phone}</div>}
                </div>
                </MDBCol>
                   <MDBCol col='6'>
               
                <div className="mb-3">
                    <label for="referby" className="form-label">Job Role: </label>
                    <select className="form-control form-select" id="jobrole" name="jobrole"   onChange={handelInput}>
                    <option value="">--Select--</option>
                    <option value="R&D">R&D</option>
                    <option value="Engineer & Technical Support">Engineer & Technical Support</option>
                    <option value="Management">Management</option>
                    <option value="Marketing & Sales">Marketing & Sales</option> 
                    <option value="Manufacturing">Manufacturing</option> 
                    <option value="Purchasing">Purchasing</option>
                    <option value="Quality & Supply Chain">Quality & Supply Chain</option>
                    <option value="Media & Consultant">Media & Consultant</option>
                    <option value="Education & Academic">Education & Academic</option>
                    <option value="Other">Other</option>
                    </select>
                    {error.jobrole && <div className="error">{error.jobrole}</div>}
                    {/* <input type="text" className="form-control" id="referby" name="referby"  onChange={handelInput} /> */}
                </div>
                </MDBCol>
              </MDBRow>

                <div className="mb-3">
                    <label for="industry" className="form-label">Industry: </label>
                    <select className="form-control form-select" id="industry" name="industry"   onChange={handelInput}>
                      <option value="">--Select--</option>
                      <option value="Automotive">Automotive</option>
                      <option value="Avionics, Military & Space">Avionics, Military & Space</option>
                      <option value="Computer">Computer</option>
                      <option value="Consumer">Consumer</option>
                      <option value="Industrial">Industrial</option>
                      <option value="Medical">Medical</option>
                      <option value="Telecommunications">Telecommunications</option>
                      <option value="Distribution Partner">Distribution Partner</option>
                      <option value="Electronics Manufacturing Services">Electronics Manufacturing Services</option>
                      <option value="Education & Academic"> Education & Academic</option>
                      <option value="Other">Other</option>
                    </select>
                    {error.industry && <div className="error">{error.industry}</div>}
                    {/* <input type="text" className="form-control" id="referby" name="referby"  onChange={handelInput} /> */}
                </div>

                <p>Vishay is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you:</p>

                
                <div class="form-check">
  <input className="form-check-input" type="checkbox" checked required value="1" name="agreeterms" id="agreeterms"/>
  <label className="form-check-label" for="defaultCheck1">
  I agree to receive other relevant marketing information about Vishay products, services and events by email.

  </label><br/>
</div>
                                 <p>You may unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.
By clicking submit below, you consent to allow Vishay to store and process the personal information submitted above to provide you the content requested.</p>
                <button type="submit" className="btn btn-primary submit-btn">Download Whitepaper</button>
                </div>
            </form>
        </div>

          </div>

        </MDBCol>

      </MDBRow>

    </MDBContainer> 

    <div className='container-fluid bg-green mr-200'>
            <Footer/>
            </div>
    </div>
  )
}

export default VisiterRegistration;