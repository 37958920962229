import React,{useEffect, useState,useRef} from 'react';
import axios from 'axios';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Header from '../Header/Header';
import DeleteIcon from '@mui/icons-material/Delete';


 function ViewVisitor() 
 {
  //http://localhost:3002/api/admin/visitor
   
   //const VISITOR_URL = "http://localhost/eventapi/index.php/auth/getVisitorData";
   const VISITOR_URL = `${process.env.REACT_APP_API_URL}auth/getWhitepaperData`;
   const delete_visitor_booking_URL = `${process.env.REACT_APP_API_URL}auth/deleteData`;

 // const navigate = useNavigate();
  const tableRef = useRef(null);
  const [visitorn,setvisitorn]= useState([]);

  async function deleteStudent(id) {
    const pdata={
      id:id,
      vtype:'vr'
    }
    const response = await fetch(delete_visitor_booking_URL, {
      method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(pdata),
    });
    return response.json();
  }

  const deleteData = async id => {
    await deleteStudent(id);
    alert('Data delete successfully');
  };

  useEffect(()=>{
    document.title="View White Paper";
   // const auth=localStorage.getItem("accessToken");
  //  console.log(auth);
   // auth?  navigate('/ViewVisitor'): navigate('/'); 
    axios.post(VISITOR_URL).then(res=>setvisitorn(res.data['data'])).catch(err=>console.log(err))},[visitorn]);

  return (
    <div>
      <Header/>
      <div className='container'>
      <div className='row'>
        <br/><br/><br/>
        <div className='col-md-8'><h3 className='mr-20'> Free Whitepaper</h3></div><div className='col-md-4 align-right'><br/>
     
      <DownloadTableExcel
                    filename="users table"
                    sheet="users"
                    currentTableRef={tableRef.current}
                >

                   <button className='btn btn-success float-end'> Export excel </button>

                </DownloadTableExcel></div>
     
      <table class="table table-bordered table-sm" ref={tableRef}>
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">CountryName</th>
        <th scope="col">CompanyName</th>
        <th scope="col">Phone</th>
        <th scope="col">Job Role</th>
        <th scope="col">Industry</th>
        <th scope="col">Submitted On</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      { visitorn &&  visitorn.map((data,i)=>(
        
      <tr>
        <th scope="row">{i+1}</th>
        <td>{data.fld_firstname} {data.fld_lastname}</td>
        <td>{data.fld_email}</td>
        <td>{data.fld_country}</td>

        <td>{data.fld_company}</td>
        <td>{data.fld_phone}</td>
        <td>{data.fld_jobrole}</td>
        <td>{data.fld_industry}</td>
        <td>{data.fld_createdon}</td>
        <td> <a 
                      className="btn btn-danger"
                      onClick={() => deleteData(data.fld_id)}
                    >
                      <DeleteIcon fontSize="small"/>
                    </a></td>
      </tr>
      ))
      }
      
    </tbody>
  </table></div></div></div>
  )
}

export default ViewVisitor;